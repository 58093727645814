<template>
  <div class="customerList">
    <v-card>
      <v-card-title>
        顧客名單
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="customerList"
        :search="search"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
//import { userAPI } from "@/api/user/api";
import axios from "axios";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  data() {
    return {
      isLoading: false,
      search: "",
      headers: [
        {
          text: "加入日期",
          align: "start",
          value: "create_time",
        },
        { text: "會員編號", value: "member_id" },
        { text: "手機號碼", value: "phone" },
        { text: "會員姓名", value: "name" },
      ],
      customerList: [],
    };
  },
  created() {
    showLoading();
    axios
      .post("http://web.hilary.com.tw:8085/getapi?type=GETCUSTOMER", {
        user_id: this.$store.state.userInfo.member_id,
      })
      .then((res) => {
        this.customerList = res.data.data;
        hideLoading();
      })
      .catch((err) => {
        console.log("error", err);
        this.$Message.error("取得顧客名單失敗");
        hideLoading();
      });
    // userAPI.getCustomerList().then((res) => {
    //   this.customerList = res.data.data;
    //   this.customerList.forEach((element) => {
    //     element.create_time = element.create_time.slice(0, 10);
    //   });
    // });
  },
};
</script>
