<template>
  <div class="account">
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="my-2 mx-10">
          <h4 class="red--text text-center">*請在7天內繳交紙本資料，資料最後依紙本上資料為準</h4>
          <v-form ref="form">
            <v-card-title
              >我的帳戶
              <v-divider vertical class="mx-2"></v-divider>
              <v-btn text small @click="changeMode()">點擊編輯</v-btn>
              <v-radio-group v-model="accountType" row :disabled="editAccountType" class="ml-auto">
                <v-radio label="個人帳號" value="0"></v-radio>
                <v-radio label="公司帳號" value="1"></v-radio>
              </v-radio-group>
            </v-card-title>
            <v-divider class="mx-5"></v-divider>
            <v-card-text>
              <v-row v-if="accountType == '0'">
                <v-col cols="4">
                  <v-subheader>姓名</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="user.name" placeholder="姓名" solo disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="accountType == '1'">
                <v-col cols="4">
                  <v-subheader class="mt-5">公司名稱</v-subheader>
                </v-col>
                <v-col cols="8">
                  <h6 class="red--text">*請輸入公司抬頭(儲存後就無法任意更改)</h6>
                  <v-text-field
                    v-if="isCompanyName"
                    v-model="user.company"
                    placeholder="公司名稱"
                    solo
                    :readonly="editMode"
                    :rules="rules.name"
                  >
                  </v-text-field>
                  <v-text-field v-if="!isCompanyName" v-model="user.company" placeholder="公司名稱" solo disabled>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-end">
                <v-col cols="4">
                  <v-subheader>信箱</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="user.email"
                    :rules="rules.emailRules"
                    placeholder="信箱"
                    solo
                    :readonly="!editEmailMode"
                  ></v-text-field>
                  <p v-if="emailAlert" class="red--text">驗證碼信件會在幾分鐘內收到請耐心等候</p>
                </v-col>
                <v-col cols="2" v-if="!editMode && !editEmailMode">
                  <v-btn block color="primary" @click="isEditEmailMode()" :loading="uploadLoading"> 修改Email </v-btn>
                </v-col>
                <v-col cols="3" v-if="editEmailMode">
                  <v-btn
                    block
                    color="primary"
                    @click="getEmailCodeWIthC()"
                    :loading="uploadLoading"
                    :disabled="emailAlert"
                  >
                    {{ getEmailCodeText }}
                  </v-btn>
                </v-col>
                <v-col cols="2" v-if="editEmailMode">
                  <v-btn block color="primary" @click="isEditEmailMode()" :loading="uploadLoading"> 取消編輯 </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!editMode && editEmailMode" class="justify-end">
                <v-col cols="4">
                  <v-subheader>驗證碼</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="emailCode" placeholder="驗證碼" solo></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn block color="primary" @click="checkEmailCode()" :loading="uploadLoading"> 驗證 </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader>手機</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="user.phone"
                    placeholder="手機"
                    solo
                    :readonly="editMode"
                    :rules="rules.phoneNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="accountType == '0'">
                <v-col cols="4">
                  <v-subheader>地址</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="user.contact_address"
                    placeholder="地址"
                    solo
                    :readonly="editMode"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="accountType == '1'">
                <v-col cols="4">
                  <v-subheader>公司地址</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="user.contact_address"
                    placeholder="公司地址"
                    solo
                    :readonly="editMode"
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="isEdit">
              <v-col>
                <v-btn color="primary" @click="dialogOpen(1)" :loading="saveWIthCLoading">儲存</v-btn>
                <v-btn text color="primary" class="mx-2" @click="$router.go('/account')">取消</v-btn>
              </v-col>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <!-- 資料補充 -->
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="my-2 mx-10">
          <v-card-title
            >資料補充
            <v-divider vertical class="mx-2" v-if="!form2"></v-divider>
            <v-btn text small @click="changeMode2()" v-if="!form2">點擊編輯</v-btn>
          </v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-form ref="form2" v-if="!form2">
            <v-card-text>
              <div v-if="accountType == '0'">
                <v-row>
                  <v-col cols="4">
                    <v-subheader>身分證名</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.id_card_name"
                      placeholder="身分證名"
                      solo
                      :readonly="editMode2"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader>身分證字號</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.id_card"
                      placeholder="身分證字號"
                      solo
                      :readonly="editMode2"
                      @keyup="uppercase"
                      :rules="rules.idCard"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader>戶籍地址</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.address"
                      placeholder="戶籍地址"
                      solo
                      :readonly="editMode2"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="accountType == '1'">
                <v-row>
                  <v-col cols="4">
                    <v-subheader>公司負責人姓名</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.owner"
                      placeholder="公司負責人姓名"
                      solo
                      :readonly="editMode2"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader>負責人身分證</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.owner_id"
                      placeholder="負責人身分證"
                      solo
                      :readonly="editMode2"
                      @keyup="uppercase2"
                      :rules="rules.idCard"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader>負責人戶籍地址</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.owner_address"
                      placeholder="負責人戶籍地址"
                      solo
                      :readonly="editMode2"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader>公司統編</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="user.tax_id"
                      placeholder="公司統編"
                      solo
                      :readonly="editMode2"
                      :rules="rules.taxId"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col cols="4">
                  <v-subheader>生日</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        placeholder="生日"
                        v-model="user.birthday"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo
                        :rules="rules.required"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="user.birthday"
                      no-title
                      scrollable
                      @input="menu = false"
                      :readonly="editMode2"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader>性別</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-radio-group v-model="user.gender" row :readonly="editMode2" :rules="rules.required">
                    <v-radio label="男" value="1"></v-radio>
                    <v-radio label="女" value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="isEdit2">
              <v-col>
                <v-btn color="primary" @click="dialogOpen(2)" :loading="saveWIthC2Loading">儲存</v-btn>
                <v-btn text color="primary" class="mx-2" @click="changeMode2()">取消</v-btn>
              </v-col>
            </v-card-actions>
          </v-form>
          <v-card-text v-if="form2">
            <h2 class="text-center red--text">您已成功上傳，無需再次上傳，如有更改之需求請聯絡客服人員。</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- --匯款資料補充-- -->
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="my-2 mx-10">
          <v-card-title>匯款資料補充 </v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text v-if="!is_upload">
            <v-row>
              <v-col cols="4">
                <v-subheader>戶名</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="bank_account_name" placeholder="戶名" solo :rules="rules.name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-subheader>行庫/分行</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  v-model="bank_branch"
                  :items="bankList"
                  item-value="BranchID"
                  :item-text="(item) => `(${item.BranchID}) ${item.BankName}-${item.BranchName}`"
                  label="行庫/分行"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-subheader>銀行帳號</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="bank_account_number" placeholder="銀行帳號" solo></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mx-5"></v-divider>
            <v-col cols="4">
              <h3>上傳照片</h3>
            </v-col>
            <v-col cols="12">
              <div v-if="!is_upload">
                <h6 class="text-center red--text">請上傳您的身分證照片，照片須清楚地顯示您的身份證資料。</h6>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-file-input
                      @change="preview_idCard_front()"
                      v-model="idCard_front"
                      label="點擊上傳身分證正面"
                      filled
                      prepend-icon="mdi-camera"
                      :rules="rules.imageStorage"
                      accept="image/png, image/jpeg, image/bmp"
                    ></v-file-input>
                    <v-img :src="idCard_front_url" max-height="50vh" contain></v-img>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input
                      @change="preview_idCard_back()"
                      v-model="idCard_back"
                      label="點擊上傳身分證反面"
                      filled
                      prepend-icon="mdi-camera"
                      :rules="rules.imageStorage"
                      accept="image/png, image/jpeg, image/bmp"
                    ></v-file-input>
                    <v-img :src="idCard_back_url" max-height="50vh" contain></v-img>
                  </v-col>
                </v-row>
                <h6 class="text-center red--text">請上傳您的存摺封面照片，照片須清楚地顯示您的存摺封面資料。</h6>
                <v-row>
                  <v-col>
                    <v-file-input
                      @change="preview_bank_front()"
                      v-model="bank_front"
                      label="點擊上傳存摺封面"
                      filled
                      prepend-icon="mdi-camera"
                      :rules="rules.imageStorage"
                      accept="image/png, image/jpeg, image/bmp"
                    ></v-file-input>
                    <v-img :src="bank_front_url" max-height="50vh" contain></v-img>
                  </v-col>
                </v-row>
                <v-btn block color="primary" @click="upload()" :loading="uploadLoading"> 上傳 </v-btn>
              </div>
            </v-col>
          </v-card-text>
          <v-card-text v-if="is_upload">
            <h2 class="text-center red--text">您已成功上傳，無需再次上傳，如有更改之需求請聯絡客服人員。</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="370px">
      <v-card>
        <v-card-title class="red--text mb-5 pt-5"><h3>*提醒</h3></v-card-title>
        <v-card-text>
          <h3>儲存後無法再次修改，請確認是否輸入正確</h3>
          <v-row class="mt-5" justify="center">
            <v-col>
              <v-btn color="primary ml-16 mr-16" @click="saveDialog()">儲存</v-btn>
              <v-btn @click="dialog = false">取消</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { userAPI } from '@/api/user/api';
import { showLoading, hideLoading } from '@/utils/loading';
import store from '@/store';
import axios from 'axios';
export default {
  data() {
    return {
      rules: {
        required: [(value) => !!value || '必填欄位'],
        name: [(v) => !!v || '必填欄位', (v) => /^[\u4e00-\u9fa5]+$/.test(v) || '只能輸入中文'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
        imageStorage: [(value) => !value || value.size < 2000000 || '大小不可以大於2MB!'],
        phoneNumber: [(v) => (v && v.length >= 10) || '請輸入正確的手機號碼'],
        idCard: [(v) => (v && v.length >= 10 && /^[A-Za-z]/.test(v)) || '請輸入正確的身分證字號'],
        taxId: [(v) => (v && v.length >= 8) || '請輸入正確的統編號碼'],
      },
      bankList: [],
      menu: false,
      accountType: '0',
      is_accountType: false,
      form2: false,
      userRole: '',
      isEdit: false,
      isEdit2: false,
      user: {},
      saveWIthCLoading: false,
      saveWIthC2Loading: false,
      emailCode: '',
      checkCode: '',
      fromEmail: '',
      editEmail: false,
      emailStatus: false,
      editEmailMode: false,
      emailAlert: false,
      getEmailCodeText: '獲取驗證碼',
      //--匯款資料補充
      is_upload: false,
      idCard_front: null,
      idCard_front_url: null,
      idCard_back: null,
      idCard_back_url: null,
      bank_front: null,
      bank_front_url: null,
      bank_branch: '',
      bank_account_name: '',
      bank_account_number: '',
      uploadLoading: false,
      isCompanyName: false,
      dialog: false,
      saveMode: 0,
    };
  },
  computed: {
    editMode() {
      return this.isEdit ? false : true;
    },
    editMode2() {
      return this.isEdit2 ? false : true;
    },
    editAccountType() {
      if (this.isEdit && this.is_accountType) {
        return true;
      }
      if (this.isEdit && !this.is_accountType) {
        return false;
      }
      if (!this.isEdit) {
        return true;
      }
    },
  },
  async created() {
    // userAPI.getUserAccount().then(res => {
    //   this.user = res.data.data;
    // });
    showLoading();
    await axios
      .post('http://web.hilary.com.tw:8085/getapi?type=SELECT1', {
        member_id: store.state.userInfo.member_id,
      })
      .then((res) => {
        if (res.status == 200 && res.data.success == true) {
          this.user = res.data.data[0];
          this.accountType = res.data.data[0].account_type;
          res.data.data[0].account_type != '' ? (this.is_accountType = true) : (this.is_accountType = false);
          this.form2 = res.data.data[0].form2;
          if (res.data.data[0].is_identification == '1') {
            this.is_upload = true;
          }
        }
        this.isCompanyName = this.user.company != '' ? false : true;
      })
      .catch((e) => {
        this.$message.error(e);
      });
    await axios
      .post('http://web.hilary.com.tw:8085/getapi?type=BANK')
      .then((res) => {
        this.bankList = res.data.data;
      })
      .catch((e) => {
        this.$message.error(e);
      });
    hideLoading();
    this.userRole = store.state.userInfo.role_id;
  },
  methods: {
    changeMode() {
      this.isEdit = this.isEdit ? false : true;
      this.$Message.info('如有更改信箱，系統將會寄發信箱驗證信至新信箱，認證通過後即會自動更新');
    },
    save() {
      if (Object.values(this.user).includes('NULL')) {
        return this.$Message.error('欄位不得有NULL');
      }
      userAPI.updateUserAccount(this.user).then((res) => {
        this.$router.go('/account');
      });
    },
    dialogOpen(i) {
      if (i == 1) {
        if (!this.$refs.form.validate()) {
          return this.$Message.error('欄位不得有NULL');
        }
      } else {
        if (this.accountType == '') return this.$Message.error('請先選擇賬號類型（個人賬號/公司賬號）');
        if (!this.$refs.form2.validate()) {
          return this.$Message.error('欄位不得有NULL');
        }
      }
      this.dialog = true;
      this.saveMode = i;
    },
    saveDialog() {
      if (this.saveMode == 1) {
        this.saveWIthC();
      }
      if (this.saveMode == 2) {
        this.saveWIthC2();
      }
    },
    saveWIthC() {
    
      if (Object.values(this.user).includes('NULL')) {
        return this.$Message.error('欄位不得有NULL');
      }
      // 如果修改Email，則判斷驗證碼是否填寫及是否通過驗證
      if (this.editEmailMode) {
        if (this.emailCode === '') {
          return this.$Message.error('驗證碼尚未填寫!');
        } else if (!this.emailStatus) {
          return this.$Message.error('Email尚未通過驗證!');
        }
      }

      if (this.$refs.form.validate()) {
        this.saveWIthCLoading = true;
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=SAVE3', {
            member_id: store.state.userInfo.member_id,
            account_type: this.accountType,
            company: this.user.company,
            email: this.user.email,
            phone: this.user.phone,
            contact_address: this.user.contact_address,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.$Message.success('儲存成功');
              window.location.reload();
            }
          })
          .catch((e) => {
            this.$message.error(e);
            this.saveWIthCLoading = false;
          });
      } else {
        this.$Message.error('請確保輸入正確的資料');
      }
      hideLoading();
    },
    saveWIthC2() {
      if (Object.values(this.user).includes('NULL')) {
        return this.$Message.error('欄位不得有NULL');
      }

      if (this.$refs.form2.validate()) {
        this.saveWIthC2Loading = true;
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=SAVE4', {
            member_id: store.state.userInfo.member_id,
            account_type: this.accountType,
            id_card: this.user.id_card,
            id_card_name: this.user.id_card_name,
            address: this.user.address,
            owner: this.user.owner,
            owner_id: this.user.owner_id,
            owner_address: this.user.owner_address,
            birthday: this.user.birthday,
            gender: this.user.gender,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.$Message.success('儲存成功');
              window.location.reload();
            }
          })
          .catch((e) => {
            this.$message.error(e);
            this.saveWIthC2Loading = false;
          });
        hideLoading();
      } else {
        this.$Message.error('請確保輸入正確的資料');
      }
    },
    uppercase() {
      this.user.id_card = this.user.id_card.toUpperCase();
    },
    uppercase2() {
      this.user.owner_id = this.user.owner_id.toUpperCase();
    },
    changeMode2() {
      this.isEdit2 = this.isEdit2 ? false : true;
    },
    save2() {
      if (Object.values(this.user).includes('NULL')) {
        return this.$Message.error('欄位不得有NULL');
      }
      // userAPI.updateUserAccount(this.user).then(res => {
      //   this.$router.go("/account");
      // });
    },
    preview_idCard_front() {
      this.idCard_front_url = URL.createObjectURL(this.idCard_front);
    },
    preview_idCard_back() {
      this.idCard_back_url = URL.createObjectURL(this.idCard_back);
    },
    preview_bank_front() {
      this.bank_front_url = URL.createObjectURL(this.bank_front);
    },
    upload() {
      let bank = this.bankList.filter((item) => {
        return item.BranchID === this.bank_branch;
      });
      if (
        this.idCard_front !== null &&
        this.idCard_back !== null &&
        this.bank_front !== null &&
        this.bank_branch !== null &&
        this.bank_account_name !== '' &&
        this.bank_account_number !== ''
      ) {
        this.$dialog['warning']({
          title: '上傳身份證、存摺封面',
          text: '請再次確認照片是否清晰。',
          persistent: true,
          actions: {
            true: { text: '上傳' },
            false: { text: '取消' },
          },
        }).then((res) => {
          if (res) {
            let formData = new FormData();
            formData.append('member_id', store.state.userInfo.member_id);
            formData.append('idcard_front', this.idCard_front);
            formData.append('idcard_back', this.idCard_back);
            formData.append('bank_front', this.bank_front);
            formData.append('bank_code', bank[0].BranchID);
            formData.append('bank_name', bank[0].BankName);
            formData.append('bank_branch', bank[0].BranchName);
            formData.append('bank_account_name', this.bank_account_name);
            formData.append('bank_account_number', this.bank_account_number);
            // userAPI.uploadIdentification(formData).then(res => {
            //   this.$Message.success(res.data.message);
            //   window.location.reload();
            // });
            this.uploadLoading = true;
            showLoading();
            axios
              .post('http://web.hilary.com.tw:8085/getapi?type=SAVEFILE', formData)
              .then((res) => {
                if (res.status == 200 && res.data.success == true) {
                  this.$Message.success('儲存成功');
                  window.location.reload();
                }
              })
              .catch((e) => {
                console.log(e);
                this.$message.error(e);
                this.uploadLoading = false;
              });
            hideLoading();
          } else {
            this.$Message.error('上傳取消');
          }
        });
      } else {
        this.$Message.error('上傳失敗，請確認資料是否齊全(身份證照片、銀行存摺照片、銀行帳號等...)');
      }
    },
    // 獲取Email驗證碼
    getEmailCodeWIthC() {
      if (this.emailAddress != '') {
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=email', {
            user_email: this.user.email,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.emailAlert = true;
              //重新獲得驗證碼倒計時
              console.log(res.data);
              let n = 180;
              this.getEmailCodeText = '重新獲得驗證碼' + n + 's';
              const run = setInterval(() => {
                n = n - 1;
                if (n < 0) {
                  clearInterval(run);
                }
                this.getEmailCodeText = '重新獲得驗證碼' + n + 's';
                if (this.getEmailCodeText < '重新獲得驗證碼' + 0 + 's') {
                  this.emailAlert = false;
                  this.getEmailCodeText = '重新獲得驗證碼';
                }
              }, 1000);
              this.fromEmail = res.data.from_email;
              this.checkCode = res.data.key;
              hideLoading();
            }
          })
          .catch((e) => {
            this.$Message.error(e);
            hideLoading();
          });
      }
    },
    // 驗證Email驗證碼
    checkEmailCode() {
      if (this.emailCode != '') {
        if (this.emailCode == this.checkCode && this.user.email == this.fromEmail) {
          this.emailStatus = true;
          this.$Message.success('驗證成功!');
        } else {
          this.emailStatus = false;
          this.$Message.error('驗證失敗!');
        }
        // signupPublicAPI
        //   .check_email_code({
        //     user_email: this.emailAddress,
        //     email_code: this.emailCode
        //   })
        //   .then(res => {
        //     if (res.data.code == 200 && res.data.data.status == true) {
        //       this.emailStatus = true;
        //       if (this.introducerStatus) {
        //         signupPublicAPI.get_member_id().then(res => {
        //           this.userName = res.data.data.member_id;
        //         });
        //       }
        //     }
        //   });
      }
    },
    isEditEmailMode() {
      this.editEmailMode = !this.editEmailMode;
    },
  },
};
</script>

<style lang="scss">
.subtitle {
  position: relative;
  bottom: 20px;
}
</style>
