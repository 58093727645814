<template>
  <div class="identification">
    <v-container fluid>
      <div v-if="!is_upload">
        <h2 class="text-center red--text">
          請上傳您的身分證照片，照片須清楚地顯示您的身份證資料。
        </h2>
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              @change="preview_idCard_front()"
              v-model="idCard_front"
              label="點擊上傳身分證正面"
              filled
              prepend-icon="mdi-camera"
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
            ></v-file-input>
            <v-img :src="idCard_front_url" max-height="50vh" contain></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              @change="preview_idCard_back()"
              v-model="idCard_back"
              label="點擊上傳身分證反面"
              filled
              prepend-icon="mdi-camera"
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
            ></v-file-input>
            <v-img :src="idCard_back_url" max-height="50vh" contain></v-img>
          </v-col>
        </v-row>
        <h2 class="text-center red--text">
          請上傳您的存摺封面照片，照片須清楚地顯示您的存摺封面資料。
        </h2>
        <v-row>
          <v-col>
            <v-file-input
              @change="preview_bank_front()"
              v-model="bank_front"
              label="點擊上傳存摺封面"
              filled
              prepend-icon="mdi-camera"
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
            ></v-file-input>
            <v-img :src="bank_front_url" max-height="50vh" contain></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="bank_type"
              :items="bankTypes"
              item-text="name"
              item-value="code"
              return-object
              placeholder="請選擇銀行類型"
              filled
              dense
            >
            </v-select>
            <v-select
              v-if="bank_type"
              v-model="bank_code"
              :items="bankCodes"
              :item-text="(item) => `(${item.code})${item.name}`"
              return-object
              item-value="code"
              placeholder="請選擇銀行"
              filled
              dense
            >
            </v-select>
            <v-select
              v-if="bank_code"
              v-model="bank_branch"
              :items="bankBranchCodes"
              item-text="name"
              placeholder="請選擇銀行分行"
              filled
              dense
            >
            </v-select>
            <v-text-field
              v-if="bank_type && bank_code && bank_branch"
              v-model="bank_account_name"
              placeholder="請輸入帳戶戶名"
            >
            </v-text-field>
            <v-text-field
              v-if="bank_type && bank_code && bank_branch && bank_account_name"
              v-model="bank_account_number"
              placeholder="請輸入帳戶號碼"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn block color="primary" @click="upload()"> 上傳 </v-btn>
      </div>
      <div v-if="is_upload">
        <h2 class="text-center red--text">
          您已成功上傳，無需再次上傳，如有更改之需求請聯絡客服人員。
        </h2>
      </div>
    </v-container>
  </div>
</template>
<script>
import { bankTypes, bankCodes, bankBranchCodes } from "@/assets/taiwan_bank";
import { userAPI } from "@/api/user/api";
// let idCard_front_url_lazy = require("@/assets/images/mall/idCard_front_sample.jpg");
// let idCard_back_url_lazy = require("@/assets/images/mall/idCard_back_sample.jpg");
// let bank_url_lazy = require("@/assets/images/mall/bank_front_sample.jpg");
export default {
  data() {
    return {
      rules: [
        (value) => !value || value.size < 2000000 || "大小不可以大於2MB!",
      ],
      is_upload: false,
      idCard_front: null,
      idCard_front_url: null,
      idCard_back: null,
      idCard_back_url: null,
      bank_front: null,
      bank_front_url: null,
      bankTypes: bankTypes,
      bankCodes: null,
      bankBranchCodes: null,
      bank_type: null,
      bank_code: null,
      bank_branch: null,
      bank_account_name: "",
      bank_account_number: "",
    };
  },
  created() {
    userAPI.getIdentification().then((res) => {
      this.is_upload = res.data.data;
    });
  },
  watch: {
    bank_type(n, o) {
      if (n != o) {
        this.bank_code = null;
        this.bank_branch = null;
        this.bank_account_name = "";
        this.bank_account_number = "";
      }
      this.bankCodes = bankCodes[this.bank_type.code];
    },
    bank_code(n, o) {
      if (n != o) {
        this.bank_branch = null;
        this.bank_account_name = "";
        this.bank_account_number = "";
      }
      this.bankBranchCodes = bankBranchCodes[this.bank_code.code];
    },
  },
  methods: {
    preview_idCard_front() {
      this.idCard_front_url = URL.createObjectURL(this.idCard_front);
    },
    preview_idCard_back() {
      this.idCard_back_url = URL.createObjectURL(this.idCard_back);
    },
    preview_bank_front() {
      this.bank_front_url = URL.createObjectURL(this.bank_front);
    },
    upload() {
      if (
        this.idCard_front !== null &&
        this.idCard_back !== null &&
        this.bank_front !== null &&
        this.bank_type !== null &&
        this.bank_code !== null &&
        this.bank_branch !== null &&
        this.bank_account_name !== "" &&
        this.bank_account_number !== ""
      ) {
        this.$dialog["warning"]({
          title: "上傳身份證、存摺封面",
          text: "請再次確認照片是否清晰。",
          persistent: true,
          actions: {
            true: { text: "上傳" },
            false: { text: "取消" },
          },
        }).then((res) => {
          if (res) {
            let formData = new FormData();
            formData.append("idcard_front", this.idCard_front);
            formData.append("idcard_back", this.idCard_back);
            formData.append("bank_front", this.bank_front);
            formData.append("bank_code", this.bank_code.code);
            formData.append(
              "bank_name",
              `${this.bank_code.name}(${this.bank_branch})`
            );
            formData.append("bank_account_name", this.bank_account_name);
            formData.append("bank_account_number", this.bank_account_number);
            userAPI.uploadIdentification(formData).then((res) => {
              this.$Message.success(res.data.message);
              window.location.reload();
            });
          } else {
            this.$Message.error("上傳取消");
          }
        });
      } else {
        this.$Message.error(
          "上傳失敗，請確認資料是否齊全(身份證、銀行存摺、銀行帳號)"
        );
      }
    },
  },
};
</script>
